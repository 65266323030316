@import "/styles/variables.scss";
.Footer {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
  grid-column: 1;
  grid-row: 3;
  text-align: left;
  background-color: #707070;
  color: #FFFFFF;

  nav {
    display: block;
  }

  .navLink {
    list-style: none;
    background-color: transparent;
    display: block;
  }

  .navLink:last-of-type {
    border-right: none;
  }

  a {
    color: #FFFFFF;
    cursor: pointer;
  }

  .socialContainer {

    p {
      margin: 0px auto;
    }

  }

  .hide {
    display: none;
  }

  .copyright {
    display: block;
    padding: 0px 0px 20px;
  }
  
}

@media screen and (min-width: 0px){

  .Footer {
    font-size: $mobile-text;

    nav h4 {
      margin: 20px 15px 0px;
    }

    .navLink a {
      display: block;
      margin: 10px 15px;
    }

    .socialContainer {
      margin: 20px 15px 10px;
    
      .social {
        font-size: 35px;
        margin: 0px 5px;
      }

      .social:first-of-type {
        margin-left: 0px;
      }

      h4 {
        margin: 0px 0px;
      }

    }

    .copyright {
      margin: 0px 15px;
    }
    
  }

}

@media screen and (min-width: 500px){

  .Footer {
    font-size: 22px;

    nav h4 {
      margin: 20px 20px 0px;
    }

    .navLink a {
      margin: 10px 20px;
    }

    .socialContainer {
      margin: 20px 20px 10px;
    
      .social {
        font-size: 40px;
        margin: 0px 7px;
      }

      h4 {
        margin: 0px;
      }

    }

    .copyright {
      margin: 0px 20px;
    }

  }

}

@media screen and (min-width: 950px){

  .Footer {
    font-size: $web-text;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 400px 250px 300px;
    -ms-grid-columns: auto auto auto;
    grid-template-rows: auto auto;
    -ms-grid-rows: auto auto;

    nav h4 {
      margin: auto;
    }
    
    nav {
      -ms-grid-column: 2;
      -ms-grid-row: 1;
      grid-column: 2;
      grid-row: 1;
      padding: 20px 20px;
      text-align: left;
      justify-self: start;
      -ms-grid-column-align: start;

      h4.hide {
        display: block;
        margin: 0px auto 10px;
      }

    }

    .navLink a {
      color: #FFFFFF;
      margin: 6px 0px;
    }

    .navLink:last-of-type {
      border-right: none;
    }

    .infoContainer {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      grid-column: 1;
      grid-row: 1;
      display: block;
      padding: 15px 20px 15px 20px;
      text-align: left;
      justify-self: start;
      -ms-grid-column-align: start;

      p {
        margin: 5px auto;
      }

      h4 {
        margin: 5px auto 10px;
      }

      span {
        display: inline-block;
        vertical-align: top; 
      }
    
      .infoLabel {
        width: 70px;
      }

    }

    .socialContainer {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      grid-column: 3;
      grid-row: 1;
      text-align: center;
      justify-self: start;
      -ms-grid-column-align: start;
      margin: 20px 20px;

      h4 {
        margin: 0px;
      }
    
      .social {
        font-size: 55px;
        margin: auto 7px;
      }

    }

    .copyright {
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      -ms-grid-row: 2;
      grid-column: 1 / 4;
      grid-row: 2;

      text-align: left;
      display: block;
      margin: 0px;
      padding: 15px 20px 15px 20px;
    }

  }

}